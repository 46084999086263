import styles from "./Typing.module.scss";

export default function Typing() {
  return (
    <div className={styles.typing}>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
    </div>
  );
}
