import React, {
  createContext,
  useReducer,
  ReactNode,
  useEffect,
} from 'react';
import { appStateReducer } from './AppReducer';
import {
  ChatHistoryLoadingState,
  CosmosDBHealth,
  historyList,
  CosmosDBStatus,
} from '../api';
import { Conversation } from '../api';
import uuid from 'react-uuid';

export interface AppState {
  isIdle: boolean;
  isChatHistoryOpen: boolean;
  chatHistoryLoadingState: ChatHistoryLoadingState;
  isCosmosDBAvailable: CosmosDBHealth;
  chatHistory: Conversation[] | null;
  filteredChatHistory: Conversation[] | null;
  currentChat: Conversation | null;
  welcomeMessages: string[];
  promptSuggestions: string[];
  defaultColor: string;
}

export type Action =
  | { type: 'TOGGLE_CHAT_HISTORY' }
  | { type: 'SET_COSMOSDB_STATUS'; payload: CosmosDBHealth }
  | { type: 'SET_IS_IDLE'; payload: boolean }
  | {
      type: 'UPDATE_CHAT_HISTORY_LOADING_STATE';
      payload: ChatHistoryLoadingState;
    }
  | { type: 'UPDATE_CURRENT_CHAT'; payload: Conversation | null }
  | {
      type: 'UPDATE_FILTERED_CHAT_HISTORY';
      payload: Conversation[] | null;
    }
  | { type: 'UPDATE_CHAT_HISTORY'; payload: Conversation } // API Call
  | { type: 'UPDATE_CHAT_TITLE'; payload: Conversation } // API Call
  | { type: 'DELETE_CHAT_ENTRY'; payload: string } // API Call
  | { type: 'DELETE_CHAT_HISTORY' } // API Call
  | { type: 'DELETE_CURRENT_CHAT_MESSAGES'; payload: string } // API Call
  | { type: 'FETCH_CHAT_HISTORY'; payload: Conversation[] | null } // API Call
  | { type: 'UPDATE_CHAT_WELCOME_MESSAGES'; payload: string[] }
  | { type: 'UPDATE_CHAT_PROMPT_SUGGESTIONS'; payload: string[] }
  | { type: 'SET_DEFUALT_COLOR'; payload: string };

const initialState: AppState = {
  isIdle: false,
  isChatHistoryOpen: false,
  chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
  chatHistory: null,
  filteredChatHistory: null,
  currentChat: null,
  welcomeMessages: [],
  promptSuggestions: ['See the menu', "I'm ready to order"],
  isCosmosDBAvailable: {
    cosmosDB: false,
    status: CosmosDBStatus.NotConfigured,
  },
  defaultColor: '#ffffff',
};

export const AppStateContext = createContext<
  | {
      state: AppState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

type AppStateProviderProps = {
  children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  // useEffect(() => {
  //   // Check for cosmosdb config and fetch initial data here
  //   const fetchChatHistory = async (): Promise<Conversation[] | null> => {
  //     const result = await historyList()
  //       .then((response) => {
  //         if (response) {
  //           dispatch({ type: "FETCH_CHAT_HISTORY", payload: response });
  //         } else {
  //           dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
  //         }
  //         return response;
  //       })
  //       .catch((err) => {
  //         dispatch({
  //           type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //           payload: ChatHistoryLoadingState.Fail,
  //         });
  //         dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
  //         console.error("There was an issue fetching your data.");
  //         return null;
  //       });
  //     return result;
  //   };

  //   const getHistoryEnsure = async () => {
  //     dispatch({
  //       type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //       payload: ChatHistoryLoadingState.Loading,
  //     });
  //     historyEnsure()
  //       .then((response) => {
  //         if (response?.cosmosDB) {
  //           fetchChatHistory()
  //             .then((res) => {
  //               if (res) {
  //                 dispatch({
  //                   type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //                   payload: ChatHistoryLoadingState.Success,
  //                 });
  //                 dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
  //               } else {
  //                 dispatch({
  //                   type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //                   payload: ChatHistoryLoadingState.Fail,
  //                 });
  //                 dispatch({
  //                   type: "SET_COSMOSDB_STATUS",
  //                   payload: {
  //                     cosmosDB: false,
  //                     status: CosmosDBStatus.NotWorking,
  //                   },
  //                 });
  //               }
  //             })
  //             .catch((err) => {
  //               dispatch({
  //                 type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //                 payload: ChatHistoryLoadingState.Fail,
  //               });
  //               dispatch({
  //                 type: "SET_COSMOSDB_STATUS",
  //                 payload: {
  //                   cosmosDB: false,
  //                   status: CosmosDBStatus.NotWorking,
  //                 },
  //               });
  //             });
  //         } else {
  //           dispatch({
  //             type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //             payload: ChatHistoryLoadingState.Fail,
  //           });
  //           dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch({
  //           type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
  //           payload: ChatHistoryLoadingState.Fail,
  //         });
  //         dispatch({
  //           type: "SET_COSMOSDB_STATUS",
  //           payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured },
  //         });
  //       });
  //   };
  //   getHistoryEnsure();
  // }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = event.data;

      if (data && data.messageKey === 'meridioai-assistant') {
        const { promptSuggestions, welcomeMessages, defaultColor } =
          data.data;
        if (welcomeMessages && welcomeMessages.length > 0) {
          dispatch({
            type: 'UPDATE_CHAT_WELCOME_MESSAGES',
            payload:
              welcomeMessages.length > 0
                ? welcomeMessages
                : [
                    'Welcome to the Meridio AI Assistant!',
                    'Are you ready to get started?!',
                  ],
          });
        }
        if (promptSuggestions && promptSuggestions.length > 0) {
          dispatch({
            type: 'UPDATE_CHAT_PROMPT_SUGGESTIONS',
            payload:
              promptSuggestions.length > 0
                ? promptSuggestions
                : ['See the menu', "I'm ready to order"],
          });
        }
        if (defaultColor) {
          dispatch({
            type: 'SET_DEFUALT_COLOR',
            payload: defaultColor ? defaultColor : '#ffffff',
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};
