interface Configuration {
  locations: {
    [location: string]: {
      logoUrl: string;
      subHeading: string;
      heading?: string;
      name?: string;
      nameCode?: string;
      accountSid?: string;
      locationSid?: string;
      headerTitle?: string;
    };
  };
}

const config: Configuration = {
  locations: {
    'zafran-pot-culvery-city': {
      logoUrl: 'https://www.zafranpot.com/img/zafran-logo.png',
      name: 'Zafron Pot',
      nameCode: 'zafran-pot-culvery-city',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'ACe7c2e1eb35b4f8ad8da00735f48318ee',
      locationSid: '69f2e59eae006545b5ed8c66b8c3040e',
      headerTitle: 'Zafran Pot Order Assistant',
    },
    'banana-leaf-culver-city': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf Culver City',
      nameCode: 'banana-leaf-culver-city',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: 'f3b3b3b3-0b3b-3b3b-b3b3-3b3b3b3b3b3b',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'banana-leaf-redondo-beach': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf Redondo Beach',
      nameCode: 'banana-leaf-redondo-beach',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: '715bd48fa412e65fddd438489bb29135',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'banana-leaf-downtown': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf Downtown',
      nameCode: 'banana-leaf-downtown',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: '0e1f22c9d9651a07636ad46eb00eff85',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'Banana Leaf San Fernando Valley': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf San Fernando Valley',
      nameCode: 'banana-leaf-san-fernando-valley',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: '05b3fc20a65f593ff98ad9242ac819f2',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'Banana Leaf Van Nuys': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf Van Nuys',
      nameCode: 'banana-leaf-van-nuys',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: '38402784320abbfc89e73d21ff79104f',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'Banana Leaf Santa Monica': {
      logoUrl: 'https://bananaleafla.com/images/logo.png',
      name: 'Banana Leaf Santa Monica',
      nameCode: 'banana-leaf-santa-monica',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC2375ec0560e2364d501bbdf505b371d7',
      locationSid: 'b2317e601bf94987b06d0997cf137fea',
      headerTitle: 'Banana Leaf Order Assistant',
    },
    'mayura-culver-city': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/mayura2-80x80.png',
      name: 'Mayura',
      nameCode: 'mayura-culver-city',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'ACb2f3eb307cd2d2c955b8c64abd67122a',
      locationSid: 'c9d49389b08bd5118c347c7a6ab01d0f',
      headerTitle: 'Mayura Order Assistant',
    },
    'shamrocks-saint-peters': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/shamrock80x80.png ',
      name: 'Shamrocks Pub & Grill',
      nameCode: 'shamrocks-saint-peters',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC217665d5a53cde36f3c62da00539a15a',
      locationSid: '2906fb8544064b8e92816187987368f1',
      headerTitle: 'Shamrocks Order Assistant',
    },
    'koyla-indian-restaurant': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/koyla2-80x80.png',
      name: 'Koyla',
      nameCode: 'koyla-indian-restaurant',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC011c91acb7d81b81311404dfce722793',
      locationSid: 'a0929a24de484c9a3954a268a491ba85',
      headerTitle: 'Koyla Order Assistant',
    },
    'hyderabad-house-restaurant': {
      logoUrl:
        'https://cdn.restrozap.com/31/images/restaurant/logo/logo.png',
      name: 'Hyderabad House',
      nameCode: 'hyderabad-house-restaurant',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC00bb901fe6966d1505147e43748793af',
      locationSid: '4d591545c126ffdeb866a3f2389326ac',
      headerTitle: 'Hyderabad House Order Assistant',
    },
    'olde-city-8th-ave': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/old-city-80x80.png',
      name: 'Olde City 8th Ave',
      nameCode: 'olde-city-8th-ave',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC179b36334c698ff97be6b6ecbc112330',
      locationSid: '79e0307140691296397b1925c53136ed',
      headerTitle: 'Olde City Order Assistant',
    },
    'olde-city-madison-ave': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/shamrock80x80.png',
      name: 'Olde City Madison Ave',
      nameCode: 'olde-city-madison-ave',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC179b36334c698ff97be6b6ecbc112330',
      locationSid: '1e7d2161d9651f52292707818b43cbe6',
      headerTitle: 'Olde City Order Assistant',
    },
    'olde-city-9th-ave': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/shamrock80x80.png',
      name: 'Olde City 9th Ave',
      nameCode: 'olde-city-9th-ave',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'AC179b36334c698ff97be6b6ecbc112330',
      locationSid: 'becf27370362fbaecf1c3785ef708273',
      headerTitle: 'Olde City Order Assistant',
    },
    'biryani-nation-concord': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/biryani-nation.png',
      name: 'Biryani Nation Concord',
      nameCode: 'biryani-nation-concord',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'ACadc81d10f166c830cebc171c4ed6e2cd',
      locationSid: 'd1e36585ba0864e2f3ec3d7475a0afa3',
      headerTitle: 'Biryani Nation Order Assistant',
    },
    'biryani-nation-ballantyne': {
      logoUrl:
        'https://meridioai.s3.us-east-2.amazonaws.com/media/chat-app/vendor-logo/biryani-nation.png',
      name: 'Biryani Nation Ballantyne',
      nameCode: 'biryani-nation-ballantyne',
      subHeading:
        'Our restaurant AI assistant will help us take your order',
      heading: 'Start Ordering',
      accountSid: 'ACadc81d10f166c830cebc171c4ed6e2cd',
      locationSid: '4fa0020b0ec00b8260828e63ce8c52e5',
      headerTitle: 'Biryani Nation Order Assistant',
    },
  },
};

export default config;
