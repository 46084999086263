import {
  UserInfo,
  ConversationRequest,
  Conversation,
  ChatMessage,
  CosmosDBHealth,
  CosmosDBStatus,
} from './models';
import { chatHistorySampleData } from '../constants/chatHistory';
import config from '../config';
import { variables } from '../variable';

const baseUrl = variables.VITE_BACKEND_BASE_URL;

export async function sendMessageToApi(
  message: string,
  conversationId: string,
  accountSid: string
) {
  try {
    const url = `${baseUrl}/Chat/conversation`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountSID: accountSid,
        conversationGuid: conversationId,
        chatMessage: message,
      }),
    });

    let messageResponse = await response.text();
    return messageResponse;
  } catch (error) {
    console.error(error);
  }
}

export async function keepAlive(
  accountId: string,
  sessionId: string
) {
  const url = `${baseUrl}/Chat/keep-alive/${accountId}/${sessionId}`;

  await fetch(url, {
    method: 'PATCH',
  });
}

export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const response = await fetch('/conversation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      messages: options.messages,
    }),
    signal: abortSignal,
  });

  return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch('/.auth/me');
  if (!response.ok) {
    console.log(
      'No identity provider found. Access to chat will be blocked.'
    );
    return [];
  }

  const payload = await response.json();
  return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
  // Make initial API call here

  // return null;
  return chatHistorySampleData;
};

export const historyList = async (): Promise<
  Conversation[] | null
> => {
  const response = await fetch('/history/list', {
    method: 'GET',
  })
    .then(async (res) => {
      const payload = await res.json();
      if (!Array.isArray(payload)) {
        console.error('There was an issue fetching your data.');
        return null;
      }
      const conversations: Conversation[] = await Promise.all(
        payload.map(async (conv: any) => {
          let convMessages: ChatMessage[] = [];
          convMessages = await historyRead(conv.id)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.error('error fetching messages: ', err);
              return [];
            });
          const conversation: Conversation = {
            id: conv.id,
            title: conv.title,
            date: conv.createdAt,
            messages: convMessages,
          };
          return conversation;
        })
      );
      return conversations;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return null;
    });

  return response;
};

export const historyRead = async (
  convId: string
): Promise<ChatMessage[]> => {
  const response = await fetch('/history/read', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async (res) => {
      if (!res) {
        return [];
      }
      const payload = await res.json();
      let messages: ChatMessage[] = [];
      if (payload?.messages) {
        payload.messages.forEach((msg: any) => {
          const message: ChatMessage = {
            id: msg.id,
            role: msg.role,
            date: msg.createdAt,
            content: msg.content,
          };
          messages.push(message);
        });
      }
      return messages;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return [];
    });
  return response;
};

export const historyGenerate = async (
  options: ConversationRequest,
  abortSignal: AbortSignal,
  convId?: string
): Promise<Response> => {
  let body;
  if (convId) {
    body = JSON.stringify({
      conversation_id: convId,
      messages: options.messages,
    });
  } else {
    body = JSON.stringify({
      messages: options.messages,
    });
  }
  const response = await fetch('/history/generate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
    signal: abortSignal,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return new Response();
    });
  return response;
};

export const historyUpdate = async (
  messages: ChatMessage[],
  convId: string
): Promise<Response> => {
  const response = await fetch('/history/update', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
      messages: messages,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async (res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDelete = async (
  convId: string
): Promise<Response> => {
  const response = await fetch('/history/delete', {
    method: 'DELETE',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
  const response = await fetch('/history/delete_all', {
    method: 'DELETE',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyClear = async (
  convId: string
): Promise<Response> => {
  const response = await fetch('/history/clear', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyRename = async (
  convId: string,
  title: string
): Promise<Response> => {
  const response = await fetch('/history/rename', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
      title: title,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export async function endChatSession(
  sessionId: string,
  accountSid: string
) {
  try {
    const url = `${baseUrl}/Chat/conversation`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountSID: accountSid,
        sessionId: sessionId,
      }),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

// export const historyEnsure = async (): Promise<CosmosDBHealth> => {
//   const response = await fetch("/history/ensure", {
//     method: "GET",
//   })
//     .then(async (res) => {
//       let respJson = await res.json();
//       let formattedResponse;
//       if (respJson.message) {
//         formattedResponse = CosmosDBStatus.Working;
//       } else {
//         if (res.status === 500) {
//           formattedResponse = CosmosDBStatus.NotWorking;
//         } else {
//           formattedResponse = CosmosDBStatus.NotConfigured;
//         }
//       }
//       if (!res.ok) {
//         return {
//           cosmosDB: false,
//           status: formattedResponse,
//         };
//       } else {
//         return {
//           cosmosDB: true,
//           status: formattedResponse,
//         };
//       }
//     })
//     .catch((err) => {
//       console.error("There was an issue fetching your data.");
//       return {
//         cosmosDB: false,
//         status: err,
//       };
//     });
//   return response;
// };
