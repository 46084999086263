import {
  Outlet,
  Link,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import styles from './Layout.module.css';
import { Stack } from '@fluentui/react';
import { HiOutlineMinusSmall, HiOutlineXMark } from 'react-icons/hi2';
import config from '../../config';
import { useEffect, useState, useContext } from 'react';
import meridioAi from '../../assets/meridio-ai.svg';
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from '@fluentui/react';
import { AppStateContext } from '../../state/AppProvider';
import { endChatSession } from '../../api';
import { GoAlert } from 'react-icons/go';
import { get } from 'lodash';

const Layout = () => {
  const { sessionId, locationName } = useParams();
  const [searchParams] = useSearchParams();
  const appStateContext = useContext(AppStateContext);
  const accountSid = searchParams.get('accountSid');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isChatValid = sessionId && accountSid && locationName;

  const minimizeChat = () => {
    window.parent.postMessage('minimizeChat', '*');
  };

  const closeChat = () => {
    setIsModalOpen(true);
  };

  const handleConfirmClose = async () => {
    if (sessionId && accountSid) {
      const broadcastChannel = new BroadcastChannel('chat_updates');
      await endChatSession(sessionId, accountSid);
      localStorage.removeItem(`meridio-chat-session:${sessionId}`);
      window.parent.postMessage('closeChat', '*');
      broadcastChannel.postMessage({ closeChat: true });
      appStateContext?.dispatch({
        type: 'UPDATE_CURRENT_CHAT',
        payload: null,
      });
      setIsModalOpen(false);
    }
  };

  const handleCancelClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isChatValid) {
      // window.location.href = 'https://www.meridioai.com';
      localStorage.clear();
    }
  }, []);

  return (
    locationName && (
      <div className={styles.layout}>
        <header
          className={styles.header}
          style={{
            backgroundColor: `${appStateContext?.state.defaultColor}`,
          }}
          role={'banner'}
        >
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="center"
            >
              <img
                alt="logo"
                src={config.locations[locationName].logoUrl}
                className={styles.headerIcon}
                aria-hidden="true"
              />
              <h3 className={styles.headerTitle}>
                {config.locations[locationName].headerTitle}
              </h3>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="center"
            >
              <HiOutlineMinusSmall
                className={styles.meridioAIMinimizeChat}
                onClick={() => minimizeChat()}
                size={24}
                color="white"
              />
              <HiOutlineXMark
                className={styles.meridioAICloseChat}
                onClick={() => closeChat()}
                size={24}
                color="white"
              />
            </Stack>
          </Stack>
        </header>
        <Outlet />
        <p className="pr-3 pb-2 pt-2 flex justify-end">
          Powered by
          <a
            className="font-bold"
            target="_blank"
            href="https://www.meridioai.com"
          >
            <img className="w-36" src={meridioAi} alt="Meridio AI" />
          </a>
        </p>
        <Dialog
          hidden={!isModalOpen}
          onDismiss={handleCancelClose}
          dialogContentProps={{
            type: DialogType.normal,
            title: (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="start"
              >
                <GoAlert size={32} />
              </Stack>
            ),
            subText:
              'Are you sure you want to close this chat and end your entire chat order?',
          }}
          modalProps={{
            isBlocking: true,
          }}
          styles={{ main: { borderRadius: 22 } }}
        >
          <DialogFooter>
            <DefaultButton
              onClick={handleConfirmClose}
              text="Yes"
              className={styles.dialogButton}
            />
            <DefaultButton
              onClick={handleCancelClose}
              text="No"
              className={styles.dialogButton}
            />
          </DialogFooter>
        </Dialog>
      </div>
    )
  );
};

export default Layout;
