import {
  useRef,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from 'react';
import { IconButton, DialogType, Stack } from '@fluentui/react';
import {
  SquareRegular,
  ShieldLockRegular,
  ErrorCircleRegular,
} from '@fluentui/react-icons';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash-es';

import { useIdleTimer } from 'react-idle-timer';

import styles from './Chat.module.scss';
import config from '../../config';

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  Conversation,
  historyGenerate,
  historyUpdate,
  historyClear,
  ChatHistoryLoadingState,
  CosmosDBStatus,
  ErrorMessage,
  keepAlive,
  sendMessageToApi,
  endChatSession,
} from '../../api';
import { Answer } from '../../components/Answer';
import { QuestionInput } from '../../components/QuestionInput';
import { ChatHistoryPanel } from '../../components/ChatHistory/ChatHistoryPanel';
import { AppStateContext } from '../../state/AppProvider';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import Typing from '../../components/Typing/Typing';

const enum messageStatus {
  NotRunning = 'Not Running',
  Processing = 'Processing',
  Done = 'Done',
}

const calculateHash = async (conversationMessages: ChatMessage[]) => {
  try {
    const jsonString = JSON.stringify(conversationMessages);
    const encoder = new TextEncoder();
    const buffer = encoder.encode(jsonString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  } catch (error) {
    console.error('Error hashing conversation:', error);
    throw error;
  }
};

const Chat = () => {
  const appStateContext = useContext(AppStateContext);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] =
    useState<boolean>(false);
  const [activeCitation, setActiveCitation] = useState<Citation>();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] =
    useState<boolean>(true);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [processMessages, setProcessMessages] =
    useState<messageStatus>(messageStatus.NotRunning);
  const [clearingChat, setClearingChat] = useState<boolean>(false);
  const [hideErrorDialog, { toggle: toggleErrorDialog }] =
    useBoolean(true);
  const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
  const { sessionId, locationName } = useParams();
  const [searchParams] = useSearchParams();

  const accountSid = searchParams.get('accountSid');
  const isChatValid = sessionId && accountSid;
  const onIdle = () => {
    console.log('is idle');

    appStateContext?.dispatch({
      type: 'SET_IS_IDLE',
      payload: true,
    });
  };

  const onActive = () => {
    appStateContext?.dispatch({
      type: 'SET_IS_IDLE',
      payload: false,
    });
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 120_000,
    events: ['keydown'],
  });

  const intervalIdRef = useRef<number | null>(null);

  const [ASSISTANT, TOOL, ERROR] = ['assistant', 'tool', 'error'];

  const broadcastChannel = useRef(
    new BroadcastChannel('chat_updates')
  );

  const [isBroadcastChannelOpen, setIsBroadcastChannelOpen] =
    useState(true);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('chat_updates');

    broadcastChannel.onmessage = (event) => {
      if (event.data.conversation) {
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: event.data.conversation,
        });
      }
      if (event.data.isLoading !== undefined) {
        setIsLoading(event.data.isLoading);
        setShowLoadingMessage(event.data.isLoading);
      }
      if (event.data.closeChat) {
        localStorage.clear();
        window.parent.postMessage('closeChat', '*');
        window.location.reload();
      }
    };

    return () => {
      broadcastChannel.close();
      setIsBroadcastChannelOpen(false);
    };
  }, [appStateContext]);

  useEffect(() => {
    if (sessionId && !appStateContext?.state.currentChat) {
      appStateContext?.dispatch({
        type: 'UPDATE_CURRENT_CHAT',
        payload: {
          date: new Date().toISOString(),
          id: sessionId,
          messages: [] as ChatMessage[],
        } as Conversation,
      });
    }

    if (sessionId) {
      const storedConversation =
        getConversationFromLocalStorage(sessionId);
      if (storedConversation) {
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: {
            date: new Date().toISOString(),
            id: sessionId,
            messages: storedConversation.messages as ChatMessage[],
          } as Conversation,
        });
      }
    }
  }, [sessionId]);

  useEffect(() => {
    if (!isChatValid) {
      localStorage.clear();
      window.parent.postMessage('closeChat', '*');
      return;
    }

    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(
        async () => await keepAlive(accountSid, sessionId),
        30_000
      );
    }

    if (intervalIdRef.current && appStateContext?.state.isIdle) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [appStateContext?.state.isIdle]);

  useEffect(() => {
    if (
      appStateContext?.state.isCosmosDBAvailable?.status ===
        CosmosDBStatus.NotWorking &&
      appStateContext.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Fail &&
      hideErrorDialog
    ) {
      let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`;
      setErrorMsg({
        title: 'Chat history is not enabled',
        subtitle: subtitle,
      });
      toggleErrorDialog();
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);

  // Default messages to display when the chat starts
  useEffect(() => {
    const loadMessages = async () => {
      const storedConversation = getConversationFromLocalStorage(
        sessionId ?? ''
      );

      if (messages.length === 0 && !storedConversation) {
        setIsLoading(true);
        setShowLoadingMessage(true);
        setProcessMessages(messageStatus.Processing);

        const wait = (ms: number) =>
          new Promise((resolve) => setTimeout(resolve, ms));

        await wait(500);

        const welcomeMessages =
          appStateContext?.state.welcomeMessages &&
          appStateContext?.state.welcomeMessages.length > 0
            ? appStateContext?.state.welcomeMessages
            : [
                'Welcome to the Meridio AI Assistant!',
                'Are you ready to get started?!',
              ];

        const defaultMessages = welcomeMessages.map(
          (content: string) => ({
            content,
            date: new Date().toISOString(),
            id: uuid(),
            role: ASSISTANT,
          })
        );

        setMessages(defaultMessages);
        const defaultConversation = {
          date: new Date().toISOString(),
          id: sessionId,
          messages: defaultMessages,
        } as Conversation;

        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: defaultConversation,
        });

        broadcastChannel.current.postMessage({
          conversation: defaultConversation,
          isLoading: false,
        });

        localStorage.setItem(
          `meridio-chat-session:${sessionId}`,
          JSON.stringify(defaultConversation as Conversation)
        );

        setIsLoading(false);
        setShowLoadingMessage(false);
        setProcessMessages(messageStatus.Done);
      }
    };

    loadMessages();
  }, [appStateContext, sessionId, messages.length]);

  const getUserInfoList = async () => {
    setShowAuthMessage(false);
  };

  let assistantMessage = {} as ChatMessage;
  let toolMessage = {} as ChatMessage;
  let assistantContent = '';

  const processResultMessage = (
    resultMessage: ChatMessage,
    userMessage: ChatMessage,
    conversationId?: string
  ) => {
    if (resultMessage.role === ASSISTANT) {
      assistantContent += resultMessage.content;
      assistantMessage = resultMessage;
      assistantMessage.content = assistantContent;
    }

    if (resultMessage.role === TOOL) toolMessage = resultMessage;

    if (!conversationId) {
      isEmpty(toolMessage)
        ? setMessages([...messages, userMessage, assistantMessage])
        : setMessages([
            ...messages,
            userMessage,
            toolMessage,
            assistantMessage,
          ]);
    } else {
      isEmpty(toolMessage)
        ? setMessages([...messages, assistantMessage])
        : setMessages([...messages, toolMessage, assistantMessage]);
    }
  };

  const makeApiRequestWithMeridio = async (
    question: string,
    conversationId?: string
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    broadcastChannel.current.postMessage({ isLoading: true });
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuid(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
      };
    } else {
      conversation = appStateContext?.state?.currentChat;
      if (!conversation) {
        console.error('Conversation not found.');
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController
        );
        return;
      } else {
        conversation.messages.push(userMessage);
      }
    }

    appStateContext?.dispatch({
      type: 'UPDATE_CURRENT_CHAT',
      payload: conversation,
    });
    broadcastChannel.current.postMessage({
      conversation: conversation,
    });

    setMessages(conversation.messages);

    let result = {} as ChatResponse;
    try {
      setProcessMessages(messageStatus.Processing);

      const answer = await sendMessageToApi(
        question,
        sessionId as string,
        accountSid as string
      );
      setShowLoadingMessage(false);

      const assistantMessage = {
        content: answer,
        date: new Date().toISOString(),
        id: uuid(),
        role: ASSISTANT,
      } as ChatMessage;

      processResultMessage(
        assistantMessage,
        userMessage,
        conversationId
      );
      conversation.messages.push(assistantMessage);

      appStateContext?.dispatch({
        type: 'UPDATE_CURRENT_CHAT',
        payload: conversation,
      });
      broadcastChannel.current.postMessage({
        conversation: conversation,
        isLoading: false,
      });

      const combinedMessages = [...messages, assistantMessage];
      setMessages(combinedMessages);
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
        };
        conversation.messages.push(errorChatMsg);
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
        broadcastChannel.current.postMessage({
          conversation: conversation,
          isLoading: false,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      );
      localStorage.setItem(
        `meridio-chat-session:${sessionId}`,
        JSON.stringify(conversation as Conversation)
      );
      setProcessMessages(messageStatus.Done);
    }

    return abortController.abort();
  };

  const getConversationFromLocalStorage = (sessionId: string) => {
    const cachedConversation = localStorage.getItem(
      `meridio-chat-session:${sessionId}`
    );
    if (cachedConversation) {
      return JSON.parse(cachedConversation);
    }
    return null;
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (appStateContext?.state.currentChat) {
      setMessages(appStateContext.state.currentChat.messages);
    } else {
      setMessages([]);
    }
  }, [appStateContext?.state.currentChat]);

  useLayoutEffect(() => {
    const saveToDB = async (messages: ChatMessage[], id: string) => {
      const response = await historyUpdate(messages, id);
      return response;
    };

    if (
      appStateContext &&
      appStateContext.state.currentChat &&
      processMessages === messageStatus.Done
    ) {
      if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
        if (!appStateContext?.state.currentChat?.messages) {
          console.error('Failure fetching current chat state.');
          return;
        }
        saveToDB(
          appStateContext.state.currentChat.messages,
          appStateContext.state.currentChat.id
        )
          .then((res) => {
            if (!res.ok) {
              let errorMessage =
                "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
              let errorChatMsg: ChatMessage = {
                id: uuid(),
                role: ERROR,
                content: errorMessage,
                date: new Date().toISOString(),
              };
              if (!appStateContext?.state.currentChat?.messages) {
                let err: Error = {
                  ...new Error(),
                  message: 'Failure fetching current chat state.',
                };
                throw err;
              }
              setMessages([
                ...appStateContext?.state.currentChat?.messages,
                errorChatMsg,
              ]);
            }
            return res as Response;
          })
          .catch((err) => {
            console.error('Error: ', err);
            let errRes: Response = {
              ...new Response(),
              ok: false,
              status: 500,
            };
            return errRes;
          });
      } else {
      }
      appStateContext?.dispatch({
        type: 'UPDATE_CHAT_HISTORY',
        payload: appStateContext.state.currentChat,
      });
      setMessages(appStateContext.state.currentChat.messages);
      setProcessMessages(messageStatus.NotRunning);
    }
  }, [processMessages]);

  useEffect(() => {
    getUserInfoList();
  }, []);

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [showLoadingMessage, processMessages]);

  const onShowCitation = (citation: Citation) => {
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
  };

  const onViewSource = (citation: Citation) => {
    if (citation.url && !citation.url.includes('blob.core')) {
      window.open(citation.url, '_blank');
    }
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role && message?.role === 'tool') {
      try {
        const toolMessage = JSON.parse(
          message.content
        ) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const handlePromptClick = (prompt: string) => {
    onActive();
    makeApiRequestWithMeridio(prompt, sessionId);
  };

  const renderPromptMessages = () => {
    if (
      appStateContext?.state.promptSuggestions &&
      appStateContext?.state.promptSuggestions.length > 0 &&
      messages.filter((msg) => msg.role === 'user').length === 0
    ) {
      return (
        <div className={styles.promptContainer}>
          <div className={styles.promptSuggestions}>
            {appStateContext.state.promptSuggestions.map(
              (prompt, index) => (
                <button
                  key={index}
                  className={styles.promptButton}
                  onClick={() => handlePromptClick(prompt)}
                >
                  {prompt}
                </button>
              )
            )}
          </div>
          <div className={styles.promptInstruction}>
            Select a prompt or ask a question to get started
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container} role="main">
      {!sessionId ? (
        <Stack className={styles.chatEmptyState}>
          <ShieldLockRegular
            className={styles.chatIcon}
            style={{
              color: 'darkorange',
              height: '200px',
              width: '200px',
            }}
          />
          <h1 className={styles.chatEmptyStateTitle}>
            Before using the chat app, information must be provided in
            the restaurant page
          </h1>
        </Stack>
      ) : (
        <Stack horizontal className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            {
              <div
                className={styles.chatMessageStream}
                style={{
                  marginBottom: isLoading ? '40px' : '0px',
                  opacity: appStateContext?.state.isIdle ? 0.6 : 1,
                }}
                role="log"
              >
                {messages.map((answer, index) => (
                  <>
                    {answer.role === 'user' ? (
                      <div
                        className={styles.chatMessageUser}
                        tabIndex={0}
                        key={uuid()}
                      >
                        <div
                          className={styles.chatMessageUserMessage}
                        >
                          {answer.content}
                        </div>
                      </div>
                    ) : answer.role === 'assistant' ? (
                      <div
                        className={styles.chatMessageGpt}
                        key={uuid()}
                      >
                        <Answer
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(
                              messages[index - 1]
                            ),
                          }}
                          onCitationClicked={(c) => onShowCitation(c)}
                        />
                      </div>
                    ) : answer.role === ERROR ? (
                      <div
                        className={styles.chatMessageError}
                        key={uuid()}
                      >
                        <Stack
                          horizontal
                          className={styles.chatMessageErrorContent}
                        >
                          <ErrorCircleRegular
                            className={styles.errorIcon}
                            style={{ color: 'rgba(182, 52, 67, 1)' }}
                          />
                          <span>Error</span>
                        </Stack>
                        <span
                          className={styles.chatMessageErrorContent}
                        >
                          {answer.content}
                        </span>
                      </div>
                    ) : null}
                  </>
                ))}
                {showLoadingMessage && (
                  <>
                    <div className={styles.chatMessageGpt}>
                      <Typing />
                    </div>
                  </>
                )}
                <div ref={chatMessageStreamEnd} />
              </div>
            }
            {renderPromptMessages()}
            {appStateContext?.state.isIdle && (
              <p className="text-center font-bold mb-2">
                Chat has been paused due to inactivity.
              </p>
            )}
            <Stack horizontal className={styles.chatInput}>
              <QuestionInput
                clearOnSend
                placeholder="Start typing..."
                disabled={isLoading}
                onSend={(question, id) => {
                  makeApiRequestWithMeridio(question, id);
                }}
                conversationId={
                  appStateContext?.state.currentChat?.id
                    ? appStateContext?.state.currentChat?.id
                    : undefined
                }
              />
            </Stack>
          </div>
          {messages &&
            messages.length > 0 &&
            isCitationPanelOpen &&
            activeCitation && (
              <Stack.Item
                className={styles.citationPanel}
                tabIndex={0}
                role="tabpanel"
                aria-label="Citations Panel"
              >
                <Stack
                  aria-label="Citations Panel Header Container"
                  horizontal
                  className={styles.citationPanelHeaderContainer}
                  horizontalAlign="space-between"
                  verticalAlign="center"
                >
                  <span
                    aria-label="Citations"
                    className={styles.citationPanelHeader}
                  >
                    Citations
                  </span>
                  <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    aria-label="Close citations panel"
                    onClick={() => setIsCitationPanelOpen(false)}
                  />
                </Stack>
                <h5
                  className={styles.citationPanelTitle}
                  tabIndex={0}
                  title={
                    activeCitation.url &&
                    !activeCitation.url.includes('blob.core')
                      ? activeCitation.url
                      : activeCitation.title ?? ''
                  }
                  onClick={() => onViewSource(activeCitation)}
                >
                  {activeCitation.title}
                </h5>
                <div tabIndex={0}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    className={styles.citationPanelContent}
                    children={activeCitation.content}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </Stack.Item>
            )}
          {appStateContext?.state.isChatHistoryOpen &&
            appStateContext?.state.isCosmosDBAvailable?.status !==
              CosmosDBStatus.NotConfigured && <ChatHistoryPanel />}
        </Stack>
      )}
    </div>
  );
};

export default Chat;
